import React, { useState } from "react";
import { Header, Sidebar } from "../components";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

export const Layout = ({ children, title }) => {
  const dispatch = useDispatch();
  const { isCollapsed } = useSelector((state) => state.companyReducer);
  // const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleDrawer = () => {
    // setIsCollapsed(!isCollapsed);
    dispatch({
      type: "SET_SIDEBAR_COLLAPSE",
    });
  };

  return (
    <React.Fragment>
      <head>
        <title>{title}</title>
      </head>

      <Grid container xs={12}>
        <Grid item xs={isCollapsed ? 0.5 : 2} style={{ height: "100vh" }}>
          <Sidebar isCollapsed={isCollapsed} toggleDrawer={toggleDrawer} />
          {!isCollapsed && (
            <KeyboardDoubleArrowLeftIcon
              sx={{
                position: "absolute",
                left: "15.5%",
                top: "6%",
                transform: "translateY(-50%) scaleX(-1)",
                color: "#b4b4b4",
                // border: "none",
                borderRadius: "100%",
                height: "30px",
                width: "30px",
                cursor: "pointer",
                background: "white",
                // fontWeight: "300",
                border: "1px solid #e4e2e2",
                padding: "3px",
                fontSize: "400px",
              }}
              onClick={toggleDrawer}
            />
          )}
        </Grid>
        <Grid
          container
          item
          xs={isCollapsed ? 11.5 : 10}
          style={{ height: "100vh" }}
        >
          <Grid item xs={12} style={{ height: "88px" }}>
            <Header />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              height: "calc(100vh - 88px)",
              padding: 30,
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            className="hide_sideBar"
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
