import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { GRAPH_TOOLTIP_SETTINGS } from "../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function StackedChartService({
  chartData,
  daysInPayroll,
  chartARDays,
}) {
  const [chartDataa, setChartData] = useState({});

  const [graphValue, setGraphValue] = useState();

  useEffect(() => {
    let graphValuee = [];
    let solidTotal = 0;
    for (let i = 0; i < Math.floor(chartData[1].x); i++) {
      graphValuee = [...graphValuee, daysInPayroll];
      solidTotal = solidTotal + daysInPayroll;
      if (i < Math.floor(chartData[1].x)) {
        if (chartData[0].x - solidTotal) {
          setGraphValue([...graphValuee, chartData[0].x - solidTotal]);
        } else {
          setGraphValue([...graphValuee]);
        }
      }
    }
  }, [chartData[1].x]);

  const fetchStackBars = () => {
    return graphValue.map((val) => ({
      data: [
        { label: "", x: "" },
        { label: "", x: val, y: val },
      ],
      backgroundColor: ["#203864", "#7a0000"],
      datalabels: {
        font: {
          size: "15px",
          clamp: true,
          weight: "bold",
        },
      },
    }));
  };

  useEffect(() => {
    if (graphValue) {
      setChartData({
        labels: ["Money In", "Money Out"],
        datasets: [
          {
            data: [chartData[0]],
            backgroundColor: ["#203864", "#7a0000"],
            datalabels: {
              font: {
                size: "15px",
                clamp: true,
                weight: "bold",
              },
            },
          },
          ...fetchStackBars(),
        ],
      });
    }
  }, [daysInPayroll, chartData, graphValue]);

  const options = {
    responsive: true,
    animation: {
      duration: 0,
    },
    indexAxis: "y",
    scales: {
      x: {
        ticks: {
          display: false,
        },
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          font: {
            size: 20,
            weight: "bold",
          },
        },
        stacked: true,
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      legend: false,
      title: {
        display: true,
        text: "Financial Gap Calculation (Payroll Optimized)",
        font: {
          size: 20,
        },
      },
      datalabels: {
        color: "white",
        formatter: (val) => {
          return val.y;
        },
      },
      tooltip: {
        enabled: true,
        titleFont: {
          size: GRAPH_TOOLTIP_SETTINGS.titleFont,
        },
        bodyFont: {
          size: GRAPH_TOOLTIP_SETTINGS.bodyFont,
        },
        padding: GRAPH_TOOLTIP_SETTINGS.padding,
      },
    },
  };

  // const data = {
  //   labels: ['Money In', 'Money Out'],
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: [chartData[0]],
  //       backgroundColor: ['#203864', '#7a0000'],
  //       datalabels: {
  //         font: {
  //           size: '15px',
  //           clamp: true,
  //           weight: 'bold',
  //         },
  //       },
  //     },
  //     graphValue.map((val) => ({
  //       label: 'Dataset 1',
  //       data: [
  //         { label: '', x: '' },
  //         { label: 'Payroll Period', x: val },
  //       ],
  //       backgroundColor: ['#203864', '#7a0000'],
  //       datalabels: {
  //         font: {
  //           size: '15px',
  //           clamp: true,
  //           weight: 'bold',
  //         },
  //       },
  //     })),
  //   ],
  // }

  // const downloadURL = () => {
  //   const imageLink = document.createElement("a");
  //   const canvas = document.getElementsByTagName("canvas");
  //   imageLink.href = canvas[0].toDataURL();
  //   setPdfGraph({ ...pdfGraph, fmfService: imageLink.href });
  // };

  return (
    <div>
      {Object.keys(chartDataa).length && graphValue ? (
        <>
          <Bar
            plugins={[ChartDataLabels]}
            options={options}
            data={chartDataa}
            // style={{ minHeight: "100%" }}
            style={{ height: "500px", width: "auto" }}
          />
          <h3 style={{ textAlign: "center" }}>
            Number of Payroll Periods Covered ={" "}
            {(chartARDays / daysInPayroll).toFixed(2)}
          </h3>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
