import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Menu,
  MenuItem,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";
import HeaderProfile from "../../assets/header-profile.svg";
import SearchIcon from "../../assets/search-icon.svg";
import FilterIcon from "../../assets/filter-icon.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../../redux/actions/user.js";

import { iniateStatesNotes } from "../../redux/actions/notes.js";
import {
  iniateStatesCompany,
  setSelectedCompany,
} from "../../redux/actions/company.js";
import {
  fetchCompanyDetails,
  iniateStatesCompanyDetails,
} from "../../redux/actions/companyDetails.js";
import { iniateStatesSnackBar } from "../../redux/actions/snackbar.js";
import { iniateStatesUser } from "../../redux/actions/user.js";

import "./style.css";

const Header = ({ page }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { company } = useSelector((state) => state.companyDetailReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { comapnies } = useSelector((state) => state.companyReducer);
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const [filterComps, setFilterComps] = useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLogout = () => {
    setAnchorEl(null);
    localStorage.clear();
    dispatch(iniateStatesNotes());
    dispatch(iniateStatesCompany());
    dispatch(iniateStatesCompanyDetails());
    dispatch(iniateStatesSnackBar());
    dispatch(iniateStatesUser());

    dispatch(signout(navigate));
  };

  const hanldeCompChange = (e, val) => {
    const payload = {
      companyId: val.value._id,
    };

    const company = val.value;

    dispatch({ type: "SET_DEFAULTVALUES_PDF" });
    dispatch(setSelectedCompany(company));
    dispatch(fetchCompanyDetails(payload));
  };

  const hanldeDefaultVal = () => {
    if (Object.keys(company).length)
      return {
        label: company.companyName,
        value: company,
      };
    return "";
  };

  const handleSearch = (e) => {
    if (!e.target.value) {
      return setFilterComps([]);
    }

    setFilterComps(
      comapnies.filter((item) =>
        item.companyName
          .toLowerCase()
          .startsWith(e?.target?.value?.toLowerCase())
      )
    );
  };

  return (
    <React.Fragment>
      <Grid container xs={12} className="header_main">
        {page === "login" || page === "company-detail" || page === "signup" ? (
          <></>
        ) : (
          <>
            <Autocomplete
              freeSolo
              onChange={hanldeCompChange}
              id="free-solo-2-demo"
              endAdornment={
                <InputAdornment position="end">
                  <img
                    src={SearchIcon}
                    alt="search-icon"
                    style={{ marginRight: 10 }}
                  />
                </InputAdornment>
              }
              disableClearable
              options={filterComps?.map((option) => ({
                label: option.companyName,
                value: option,
              }))}
              defaultValue={hanldeDefaultVal}
              renderInput={(params) => (
                <Box className="header_search_bar">
                  <img
                    src={SearchIcon}
                    alt="search-icon"
                    style={{ marginRight: 10 }}
                  />
                  <TextField
                    style={{ width: "700px" }}
                    {...params}
                    placeholder="Select company"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // Removes the default border
                        },
                        "&:focused": {
                          border: "none", // Ensures there's no border when focused
                        },
                        "&:hover fieldset": {
                          border: "none", // Removes the hover border
                        },
                      },
                    }}
                    onChange={handleSearch}
                    className="header_autoComplete"
                    endAdornment={
                      <InputAdornment position="end">
                        <img
                          src={SearchIcon}
                          alt="search-icon"
                          style={{ marginRight: 10 }}
                        />
                      </InputAdornment>
                    }
                  />
                </Box>
              )}
            />
          </>
        )}
        <Box></Box>
        {token ? (
          <Box className="header_profile_box">
            {/* <img src={HeaderProfile} alt="Img" className="HeaderProfile" /> */}
            <Typography className="header_profile_typo">
              <span className="header_user_name">{user.name}</span>
              <br />
              <span className="header_user_mail">{user.email}</span>
            </Typography>
            <MoreVertIcon
              style={{ marginLeft: 10, color: "#606060", cursor: "pointer" }}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate("/company-detail");
                }}
              >
                Company
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleCloseLogout();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <p>LOGIN</p>
        )}
      </Grid>
    </React.Fragment>
  );
};
export default Header;
