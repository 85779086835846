import React, { useState, useEffect } from "react";
import { Grid, Box, CircularProgress, Tooltip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { VscFeedback } from "react-icons/vsc";
import { HiOutlineHome } from "react-icons/hi2";
import { FaRegNoteSticky } from "react-icons/fa6";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { SiMoneygram } from "react-icons/si";
import { TbHome2 } from "react-icons/tb";
import { IoBagOutline } from "react-icons/io5";
import { TiCloudStorageOutline } from "react-icons/ti";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { useSelector } from "react-redux";
import SnackBar from "../snackBar";
import { FaStethoscope } from "react-icons/fa6";
import "./style.css";
import logo from "../../images/logo2.png";
import { useDispatch } from "react-redux";
import { clientCheck } from "../../utils/utilFunctions";
import SportsIcon from "@mui/icons-material/Sports";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";

const Sidebar = ({ toggleDrawer }) => {
  const { user } = useSelector((state) => state.userReducer);
  const { isCollapsed } = useSelector((state) => state.companyReducer);
  const { graphLoading } = useSelector((state) => state.pdfGraphReducer);
  const { company } = useSelector((state) => state.companyDetailReducer);
  const dispatch = useDispatch();

  const { selectedCompany } = useSelector((state) => state.companyReducer);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let token = localStorage.getItem("token");
    let PStatus = localStorage.getItem("paymentStatus");
    let cpcp_subscribed = localStorage.getItem("cpcp_subscribed");
    let lastLoginTime = localStorage.getItem("last_login_time");
    const currentTime = new Date();

    if (
      !lastLoginTime ||
      currentTime - new Date(lastLoginTime) > 24 * 60 * 60 * 1000
    ) {
      localStorage.clear();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/");
    }

    if (
      (!token || token === "undefined") &&
      (!PStatus || PStatus !== "true") &&
      (!cpcp_subscribed || cpcp_subscribed !== "true")
    ) {
      localStorage.clear();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/");
    } else if (PStatus === "false" && cpcp_subscribed === "false") {
      navigate("/subscription");
    } else if (!Object.keys(company).length) {
      if (location.pathname !== "/company-detail") navigate("/home");
    }
  }, [navigate]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const sidebarTabs = [
    {
      icon: HiOutlineHome,
      label: "Home",
      route: "/home",
      disabled: false,
    },
    {
      icon: SportsIcon,
      label: "Client Conversation Coach",
      route: "/coach",
      disabled: user?.isClient ? true : false,
    },
    {
      icon: PauseCircleOutlineIcon,
      label: "Start With End In Mind",
      route: "/start-with-end-in-mind",
      disabled: false,
    },
    {
      icon: FaRegNoteSticky,
      label: "Financial Data",
      route: "/financial-data",
      disabled: user.isClient,
    },
    {
      icon: HiOutlineCurrencyDollar,
      label: "Hidden Cash",
      route: "/hidden-cash",
      disabled: false,
    },
    {
      icon: SiMoneygram,
      label: "Fast Money Formula",
      route: "/fast-money-formula",
      disabled: false,
    },
    {
      icon: TbHome2,
      label: "Home Run",
      route: "/home-run",
      disabled: false,
    },
    {
      icon: IoBagOutline,
      label: "Simple Valuation",
      route: "/simple-valuation",
      disabled: false,
    },
    {
      icon: FaStethoscope,
      label: "Financial Doctor",
      route: "/financial-doctor",
      disabled: false,
    },
    {
      icon: TiCloudStorageOutline,
      label: "Create PDF",
      route: "/pdf",
      disabled: graphLoading || user.isClient,
    },
  ];

  const handleNavigate = (route) => {
    if (
      Object.keys(selectedCompany).length ||
      route === "/home" ||
      route === "/contact-us"
    )
      return navigate(route);

    setOpen(true);
    setMessage("Please Select Company First");
  };

  return (
    <React.Fragment>
      <Grid
        className="sidebar_main"
        style={{
          transition: "width 0.3s",
          width: isCollapsed ? "90px" : "100%",
          position: "relative",
        }}
      >
        <Box className="sidebar_ThomsonLogo">
          {!isCollapsed && (
            <img
              // src={user.logo ? user.logo : logo}
              src={logo}
              alt="Logo"
              className="sidebar_ThomsonLogo"
              onClick={() => handleNavigate("/home")}
            />
          )}
          {isCollapsed && (
            <KeyboardDoubleArrowRightIcon
              onClick={toggleDrawer}
              style={{
                color: "#b4b4b4",
                cursor: "pointer",
                margin: "20px",
                fontSize: "30px",
              }}
            />
          )}
        </Box>
        {sidebarTabs?.map((tab, index) => (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: "none",
                  backgroundColor: "#F8F8FF",
                  color: "gray",
                  paddingY: "10px",
                  paddingX: "20px",
                  display: !isCollapsed ? "none" : "",
                },
              },
            }}
            title={
              <pre style={{ fontSize: "13px", fontWeight: "600" }}>
                {isCollapsed ? tab?.label : ""}
              </pre>
            }
            placement="right"
          >
            <Box
              className={` ${
                user.isClient && tab?.disabled
                  ? "disabled_sidebar_tab"
                  : "sidebar_tab"
              } ${
                window.location.pathname === tab?.route && "sidebar_tab_active"
              } `}
              key={index}
              onClick={() => {
                if (user.isClient && tab?.disabled) clientCheck(user, dispatch);
                if (!tab.disabled) handleNavigate(tab?.route);
              }}
            >
              <tab.icon style={{ marginRight: 10, fontSize: 19 }} />
              {!isCollapsed && <div>{tab?.label}</div>}
              {tab.route === "/pdf" && tab.disabled && !user.isClient ? (
                <CircularProgress
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5%",
                  }}
                />
              ) : (
                ""
              )}
            </Box>
          </Tooltip>
        ))}
        <Box
          onClick={() => handleNavigate("/contact-us")}
          className="contact_us"
          style={{
            marginTop: "80%",
            position: "fixed",
            bottom: 10,
            left: 10,
            textAlign: "center",
          }}
        >
          <VscFeedback style={{ marginRight: 10, fontSize: 25 }} />
          {!isCollapsed && <div>Contact us</div>}
        </Box>
        {/* {!isCollapsed && (
          <KeyboardTabIcon
            style={{
              position: "absolute",
              left: "95%",
              top: "4vh",
              transform: "translateY(-50%) scaleX(-1)",
              color: "#b4b4b4",
              border: "none",
              borderRadius: "50%",
              height: "30px",
              width: "30px",
              cursor: "pointer",
              background: "white",
              fontWeight: "300",
            }}
            onClick={toggleDrawer}
          />
        )} */}
      </Grid>
      <SnackBar
        setOpen={setOpen}
        open={open}
        snackType={"error"}
        msg={message}
      />
    </React.Fragment>
  );
};
export default Sidebar;
