import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import "./style.css";
import TrashIcon from "../../assets/trash-icon.svg";
import EditIcon from "../../assets/edit-icon.svg";
import EditIcon2 from "@mui/icons-material/ModeEdit";
import DeleteIcon1 from "@mui/icons-material/DeleteOutline";
// import { fetchTeamMembers } from "../../redux/actions/user.js";
import IOSSwitch from "../Toggle/index.js";
import CheckBox from "../checkbox/index.jsx";
import {
  fetchCompanies,
  updateCompany,
  deleteCompany,
  deselectCompany,
  UpdateMikeDemoComp,
  setSelectedCompany,
  updateCompanyAccess,
} from "../../redux/actions/company.js";
import {
  fetchCompanyDetails,
  emptyStates,
} from "../../redux/actions/companyDetails.js";
import { useDispatch, useSelector } from "react-redux";
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import CircularProgress from "@material-ui/core/CircularProgress";
import UpdateIcon from "@mui/icons-material/Update";
import { clearSnack, clientCheck } from "../../utils/utilFunctions.js";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const generateRandomLightColor = () => {
  return `#b7959b`;
  return `#7756F9`;
  return `#F98F2E`;
  return `#EEC01E`;
  return `#0CC556`;
};

// const generateRandomLightColor = () => {
//   const lightColors = [
//     "#7756F9", // Purple
//     "#b7959b", // Light Pink
//     "#F98F2E", // Orange
//     "#EEC01E", // Yellow
//     "#0CC556", // Green
//   ];

//   // Randomly pick a color from the array
//   const randomIndex = Math.floor(Math.random() * lightColors.length);
//   return lightColors[randomIndex];
// };

const CompanyLogo = ({ companyName }) => {
  // Get the first letter of the company name
  const firstLetter = companyName ? companyName.charAt(0).toUpperCase() : "";

  // Style for the red circle
  const circleStyle = {
    width: "40px", // Set your desired logo size
    height: "40px", // Set your desired logo size
    borderRadius: "50%",
    backgroundColor: generateRandomLightColor(),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "1.5em",
    marginRight: "10px",
  };

  return <div style={circleStyle}>{firstLetter}</div>;
};

// const MIKE_USERID = "646132b59a540a29d8962ee5"; // Testing db user
// const MIKE_DEMO_COMP_ID = "64934fe9d1155a08d01d80ba"; // Testing db user

const MIKE_USERID = "646a94fbebe22b0021a686b9"; // PROD ID attached
const MIKE_DEMO_COMP_ID = "649c75b20e8e5f0021adb1ac"; // PROD ID attached

const HomeTable = ({
  selectedCompany,
  handleOpen,
  setEdit,
  setState,
  setSendCompanyId,
  members,
  handleNoMember,
  setNewMembers,
  handleShareWithButton,
  setSearchNAICS,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedItems, setCheckedItems] = useState();

  // const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [memberComp, setMemberComp] = useState("");

  const handleSharedMembersList = (sharedWithEmail) => {
    if (sharedWithEmail.length) setSelectedMembers([...sharedWithEmail]);
  };

  const handleClick = (event, comp) => {
    setMemberComp(comp);
    handleSharedMembersList(comp.sharedWithEmail);

    setAnchorEl(event.currentTarget);
  };

  const handleUpdateSharedMemberList = () => {
    const payload = {
      updatedComp: {
        ...memberComp,
        sharedWithEmail: selectedMembers,
      },
      userEmail,
    };

    dispatch(updateCompanyAccess(payload));
  };

  const handleClose = () => {
    if (members && members.length) {
      handleUpdateSharedMemberList();
    }

    setSelectedMembers([]);
    setAnchorEl(null);
  };

  const handleToggle = (item) => {
    let dummySelected = [...selectedMembers];

    if (selectedMembers.includes(item)) {
      dummySelected = dummySelected.filter((ds) => ds !== item);
    } else {
      dummySelected = [...dummySelected, item];
    }

    setSelectedMembers(dummySelected);
  };

  const dispatch = useDispatch();

  const { filteredCompanies, comapnies, isCompHide } = useSelector(
    (state) => state.companyReducer
  );

  const {
    id: userId,
    userEmail,
    user,
  } = useSelector((state) => state.userReducer);

  const hideCompanies = () => {
    dispatch({
      type: "HIDE_COMP",
    });
  };

  const showCompanies = () => {
    dispatch({
      type: "SHOW_COMP",
    });
  };

  const handleUpdateServiceCheck = (company, e) => {
    if (!clientCheck(user, dispatch)) {
      return;
    }
    const payload = {
      companyName: company.companyName,
      NAICScode: company.NAICScode,
      isService: e.target.checked,
      loggedInUserEmail: userEmail,
    };
    dispatch(updateCompany(payload, company._id));
    if (selectedCompany?._id === company._id) {
      const payload = {
        ...selectedCompany,
        isService: e.target.checked,
      };

      dispatch({
        type: "UPDATE_SELECTED_COMP_DETAIL",
        payload: payload,
      });

      dispatch({
        type: "SET_SELECTED_COMP",
        payload: payload,
      });
    }
  };

  const handleSelectComp = (comp) => {
    if (comp._id === selectedCompany._id) {
      dispatch(deselectCompany());
      dispatch(emptyStates());
      return;
    }

    const payload = {
      companyId: comp._id,
    };

    dispatch(setSelectedCompany(comp));
    dispatch(fetchCompanyDetails(payload));
  };

  const handleEdit = (comp) => {
    if (!clientCheck(user, dispatch)) {
      return;
    }
    setSearchNAICS(comp.NAICScode);
    setSendCompanyId(comp._id);
    handleOpen();
    setEdit(true);
    setState((prev) => ({
      ...prev,
      companyName: comp.companyName,
      code: comp.NAICScode,
      currency: comp.currency,
      industry: comp.industry,
      isService: comp.isService,
    }));
    setNewMembers(comp.sharedWithEmail);
  };

  const handleDelete = (comp) => {
    if (!clientCheck(user, dispatch)) {
      return;
    }
    if (userId !== comp.userId) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Only owner can delete company",
        },
      });
      clearSnack(dispatch);
      return;
    }

    dispatch(deleteCompany({ companyId: comp._id, userEmail }));
    dispatch(fetchCompanies({ userId: localStorage.getItem("id"), userEmail }));
  };

  const handleUpdateDemo = () => {
    dispatch(UpdateMikeDemoComp());
  };

  // const handleNoMember = () => {
  //   for (let i = 0; i < members?.length; i++) {
  //     if (members[i].isSignedUp) {
  //       i = members.length;
  //       return false;
  //     }
  //     if (i < members.length - 1) {
  //       return true;
  //     }
  //   }
  // };

  const handleTotalShared = (comp) => {
    const totalCount =
      comp.sharedWithEmail.length +
      comp.subsSharedWithEmail.length +
      comp.clntsShareWithEmail.length;

    return totalCount > 0 ? `(${totalCount})` : "";
  };

  return (
    <React.Fragment>
      <Grid
      // style={{
      //   background: "linear-gradient(180deg, ##D7D1EC, #d5f3f2)",
      // }}
      >
        <Grid
          container
          item
          xs={12}
          style={{
            height: "calc(85%)",
          }}
        >
          <Grid
            container
            item
            xs={12}
            className="home_table_heading_row"
            style={{ height: "auto" }}
          >
            <Grid item xs={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                Company name{" "}
                {isCompHide ? (
                  <LuEyeOff className="eye" onClick={showCompanies} />
                ) : (
                  <LuEye className="eye" onClick={hideCompanies} />
                )}
              </div>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              Share Access
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              NAICS code
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              Service
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              Action
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className="hide_sideBar"
            style={{
              overflowY: "scroll",
              borderRadius: 10,
              height: "calc(100%)",
              minHeight: "calc(100%)",
              maxHeight: 1,
            }}
          >
            {comapnies.length ? (
              (filteredCompanies?.length
                ? [...filteredCompanies]
                : [...comapnies]
              ).map((item, index) => (
                <Grid
                  key={index + 100}
                  container
                  xs={12}
                  className="home_table_data_row d-flex ai-center"
                >
                  <Grid item xs={3}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <CheckBox
                        checked={
                          selectedCompany._id === item._id ? true : false
                        }
                        onClick={() => handleSelectComp(item)}
                      />
                      <CompanyLogo companyName={item.companyName} />
                      <Typography
                        className={
                          isCompHide
                            ? selectedCompany._id === item._id
                              ? "comp_name"
                              : "comp_name blur_comp_name"
                            : "comp_name"
                        }
                      >
                        {item.companyName}
                      </Typography>{" "}
                      {item._id === MIKE_DEMO_COMP_ID &&
                        userId === MIKE_USERID && (
                          <Tooltip
                            title={
                              <pre style={{ fontSize: "16px" }}>
                                Update for users <br />
                                Tip: Only update when user traffic is low
                              </pre>
                            }
                            arrow
                            placement="top-start"
                          >
                            <Button
                              onClick={handleUpdateDemo}
                              className="update_demo_company_btn"
                            >
                              <UpdateIcon />
                            </Button>
                          </Tooltip>
                        )}
                    </Box>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "center" }}>
                    <Button
                      className={
                        user.isClient ? "disabled-anchor-btn" : "anchor-btn"
                      }
                      style={{
                        padding: "5px 8px",
                        borderRadius: "5px",
                        // width: "100px",
                        backgroundColor: "#00C696",
                      }}
                      // disabled={loading}
                      onClick={(e) => handleShareWithButton(item)}
                    >
                      Share {handleTotalShared(item)}
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            maxWidth: "none",
                            backgroundColor: "#F8F8FF",
                            color: "gray",
                            paddingY: "10px",
                            paddingX: "20px",
                          },
                        },
                      }}
                      title={
                        <pre style={{ fontSize: "13px", fontWeight: "600" }}>
                          {item.NAICStitle}
                        </pre>
                      }
                      placement="top"
                      // arrow
                    >
                      <Grid
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <InfoOutlinedIcon
                          // onClick={() => handlInfo("company")}
                          className="naics_info"
                        />
                        <Typography
                          style={{ cursor: "pointer", color: "black" }}
                        >
                          {item.NAICScode}
                        </Typography>
                      </Grid>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <IOSSwitch
                      style={{
                        color: user.isClient ? "rgb(158, 155, 155) " : "",
                      }}
                      onChange={(e) => handleUpdateServiceCheck(item, e)}
                      checked={item.isService}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <EditIcon2
                      onClick={() => handleEdit(item)}
                      // src={EditIcon}
                      className="Home_Action_button"
                      style={{ cursor: "pointer" }}
                      alt="edit icon"
                    />

                    <DeleteIcon1
                      onClick={() => handleDelete(item)}
                      // src={TrashIcon}
                      className="Home_Action_button"
                      style={{ cursor: "pointer" }}
                      alt="delete icon"
                    />
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "65%",
                  color: "#c5c5c5",
                  minHeight: "235px",
                }}
              >
                Add Company To Start
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {handleNoMember() ? (
          <p
            style={{
              padding: "10px",
              color: "gray",
            }}
          >
            No member added yet
          </p>
        ) : (
          members?.map((member) => {
            if (member.isSignedUp)
              return (
                <MenuItem onClick={() => handleToggle(member.memberEmail)}>
                  <CheckBox
                    checked={selectedMembers.includes(member.memberEmail)}
                  />
                  <ListItemText primary={member.memberEmail} />
                </MenuItem>
              );
          })
        )}
      </Menu>
    </React.Fragment>
  );
};
export default HomeTable;
