const PERIOD_DURATION = [
  { label: "Year" },
  { label: "Quarter" },
  { label: "Month" },
  { label: "Year To Date" },
];

const OWNER_GOAL = [
  {
    label: "Sales to Assets",
    placeholder: "Enter Sales to Assets",
    industryAvg: "salesToAssets",
    name: "salesToAssets",
  },
  {
    label: "Gross Profit",
    placeholder: "Enter Gross Profit",
    industryAvg: "grossProfitePercentage",
    percentage: true,
    name: "grossProfitePercentage",
  },
  {
    label: "Net Profit",
    placeholder: "Enter Net Profit",
    industryAvg: "netProfitePercentage",
    percentage: true,
    name: "netProfitePercentage",
  },
  {
    label: "Inventory Days",
    placeholder: "Enter Inventory Days",
    industryAvg: "inventoryTurnoverRate",
    name: "inventoryTurnoverRate",
    turnover: true,
  },
  {
    label: "AR Days",
    placeholder: "Enter AR Days",
    industryAvg: "accountsReceivableTurnoverRate",
    name: "accountsReceivableTurnoverRate",
    turnover: true,
  },
  {
    label: "AP Days",
    placeholder: "Enter AP Days",
    industryAvg: "accountsPayableTurnoverRate",
    name: "accountsPayableTurnoverRate",
    turnover: true,
  },
  {
    label: "EBITDA as % of Sales",
    placeholder: "Enter EBITDA as % of Sales",
    industryAvg: "ebidta",
    percentage: true,
    name: "ebidta",
  },
  {
    label: "Return on Assets Ratio",
    placeholder: "Enter Return on Assets Ratio",
    industryAvg: "roa",
    name: "roa",
  },
  {
    label: "Return on Investment Ratio",
    placeholder: "Enter Return on Investment Ratio",
    industryAvg: "roi",
    name: "roi",
  },
  {
    label: "Current Ratio",
    placeholder: "Enter Current Ratio",
    industryAvg: "currentRatio",
    name: "currentRatio",
  },
  {
    label: "Quick Ratio",
    placeholder: "Enter Quick Ratio",
    industryAvg: "quickRatio",
    name: "quickRatio",
  },
  {
    label: "Debt to Equity Ratio",
    placeholder: "Enter Debt to Equity Ratio",
    industryAvg: "debtToEquityRatio",
    name: "debtToEquityRatio",
  },
  {
    label: "Days Cash On Hand",
    placeholder: "Enter Days Cash On Hand",
    // industryAvg: "debtToEquityRatio",
    name: "dcoh",
  },
];

const INCOME_STATEMENT = [
  {
    label: "Sales",
    placeholder: "Enter Sales",
    name: "salesinUSD",
  },
  {
    label: "Cost of Goods Sold",
    placeholder: "Enter Cost of Goods Sold",
    name: "costOfGoodsSoldinUSD",
  },
  {
    label: "Gross Profit",
    placeholder: "Enter Gross Profit",
    name: "grossProfiteinUSD",
  },
  {
    label: "Operating Expense",
    placeholder: "Enter Operating Expense",
    name: "operatingExpense",
  },
  {
    label: "Depreciation Expense",
    placeholder: "Enter Depreciation Expense",
    name: "depExpense",
  },
  {
    label: "Amortization Expense",
    placeholder: "Enter Amortization Expense",
    name: "amortExpense",
  },
  {
    label: "Interest Expense",
    placeholder: "Enter Interest Expense",
    name: "interestExpense",
  },
  {
    label: "Taxes Paid",
    placeholder: "Enter Taxes Paid",
    name: "taxesPaid",
  },

  {
    label: "Average Fully Loaded Payroll Expense",
    placeholder: "Enter Average Fully Loaded Payroll Expense",
    name: "avgPayrollExpense",
  },
  {
    label: "Number of Days In Payroll Period",
    placeholder: "Enter Number of Days In Payroll Period",
    name: "daysInPayroll",
  },

  {
    label: "Net Profit Before Tax",
    placeholder: "Enter Net Profit Before Tax",
    name: "netProfiteBeforeTaxinUSD",
  },
  {
    label: "Family Excess / Seller’s Discretionary Earnings",
    placeholder: "Enter Family Excess",
    name: "familyExcess",
  },
];

const BALANCE_SHEET = [
  {
    label: "Current Assets",
    placeholder: "Enter Current Assets",
    industryAvg: true,
    name: "currenAssets",
  },
  {
    label: "Cash",
    placeholder: "Enter Cash",
    name: "cash",
  },
  {
    label: "Accounts Receivable",
    placeholder: "Enter Accounts Receivable",
    name: "accountReceivableinUSD",
  },
  {
    label: "Inventory",
    placeholder: "Enter Inventory",
    name: "inventoryinUSD",
  },
  {
    label: "Gross Fixed Assets",
    placeholder: "Enter Gross Fixed Assets",
    name: "grossFixedAssets",
  },
  {
    label: "Total Assets",
    placeholder: "Enter Total Assets",
    name: "totalAssetsinUSD",
  },
  {
    label: "Current Liabilities",
    placeholder: "Enter Current Liabilities",
    name: "currenLiabilities",
  },
  {
    label: "Accounts Payable",
    placeholder: "Enter Accounts Payable",
    name: "accountPayableinUSD",
  },
  {
    label: "Long Term Debt",
    placeholder: "Enter Long Term Debt",
    name: "longTermDebt",
  },
  {
    label: "Debt Payments",
    placeholder: "Enter Debt Payments",
    name: "debtPayments",
  },
  {
    label: "Total Liabilities",
    placeholder: "Enter Total Liabilities",
    name: "totalLiabilities",
  },
  {
    label: "Retained Earnings",
    placeholder: "Enter Retained Earnings",
    name: "retainedEarnings",
  },
  {
    label: "Total Equity",
    placeholder: "Enter Total Equity",
    name: "totalEquity",
  },
  {
    label: "Capital Expenditures this period​",
    placeholder: "Enter Capital Expenditures",
    name: "capx",
  },
];

const CASH_FLOW_STATEMENT = [
  {
    label: "Operating Activities",
    placeholder: "Enter Operating Activities",
    name: "operatingActivities",
  },
  {
    label: "Investing Activities",
    placeholder: "Enter Investing Activities",
    name: "investingActivities",
  },
  {
    label: "Financing Activities",
    placeholder: "Enter Financing Activities",
    name: "financingActivities",
  },
];

const HIDDEN_CASH_OWNER_GOAL = [
  {
    label: "Sales to Assets",
    placeholder: "Enter Sales to Assets",
    industryAvg: "salesToAssets",
    name: "salesToAssets",
  },
  {
    label: "Gross Profit",
    placeholder: "Enter Gross Profit",
    industryAvg: "grossProfitePercentage",
    percentage: true,
    name: "grossProfitePercentage",
  },
  {
    label: "Net Profit",
    placeholder: "Enter Net Profit",
    industryAvg: "netProfitePercentage",
    percentage: true,
    name: "netProfitePercentage",
  },
  {
    label: "Inventory Days",
    placeholder: "Enter Inventory Days",
    industryAvg: "inventoryTurnoverRate",
    name: "inventoryTurnoverRate",
    turnover: true,
  },
  {
    label: "AR Days",
    placeholder: "Enter AR Days",
    industryAvg: "accountsReceivableTurnoverRate",
    name: "accountsReceivableTurnoverRate",
    turnover: true,
  },
  {
    label: "AP Days",
    placeholder: "Enter AP Days",
    industryAvg: "accountsPayableTurnoverRate",
    name: "accountsPayableTurnoverRate",
    turnover: true,
  },
];

const HIDDEN_CASH_INCOME_STATEMENT = [
  {
    label: "Sales",
    placeholder: "Enter Sales",
    name: "salesinUSD",
  },
  {
    label: "Cost of Goods Sold",
    placeholder: "Enter Cost of Goods Sold",
    name: "costOfGoodsSoldinUSD",
  },
  {
    label: "Gross Profit",
    placeholder: "Enter Gross Profit",
    name: "grossProfiteinUSD",
  },
  {
    label: "Operating Expense",
    placeholder: "Enter Operating Expense",
    name: "operatingExpense",
  },

  {
    label: "Net Profit Before Tax",
    placeholder: "Enter Net Profit Before Tax",
    name: "netProfiteBeforeTaxinUSD",
  },
];
const HIDDEN_CASH_BALANCE_SHEET = [
  {
    label: "Accounts Receivable",
    placeholder: "Enter Accounts Receivable",
    name: "accountReceivableinUSD",
  },
  {
    label: "Inventory",
    placeholder: "Enter Inventory",
    name: "inventoryinUSD",
  },
  {
    label: "Total Assets",
    placeholder: "Enter Total Assets",
    name: "totalAssetsinUSD",
  },
  {
    label: "Accounts Payable",
    placeholder: "Enter Accounts Payable",
    name: "accountPayableinUSD",
  },
];

const FAST_MONEY_INCOME_STATEMENT = [
  {
    label: "Sales",
    placeholder: "Enter sales",
    name: "salesinUSD",
  },
  {
    label: "Cost of Goods Sold",
    placeholder: "Enter Cost of Goods Sold",
    name: "costOfGoodsSoldinUSD",
  },
  {
    label: "Average Fully Loaded Payroll Expense",
    placeholder: "Enter Average Fully Loaded Payroll Expense",
    name: "avgPayrollExpense",
  },
  {
    label: "Number of Days In Payroll Period",
    placeholder: "Enter Number of Days In Payroll Period",
    name: "daysInPayroll",
  },
];
const FAST_MONEY_BALANCE_SHEET = [
  {
    label: "Current Assets",
    placeholder: "Enter Current Assets",
    name: "currenAssets",
  },
  {
    label: "Accounts Receivable",
    placeholder: "Enter Accounts Receivable",
    name: "accountReceivableinUSD",
  },
  {
    label: "Inventory",
    placeholder: "Enter Inventory",
    name: "inventoryinUSD",
  },
  {
    label: "Current Liabilities",
    placeholder: "Enter Current Liabilities",
    name: "currenLiabilities",
  },
  {
    label: "Accounts Payable",
    placeholder: "Enter Accounts Payable",
    name: "accountPayableinUSD",
  },
];

const HOME_RUN_INCOME_STATEMENT = [
  {
    label: "Sales",
    placeholder: "Enter Sales",
    name: "salesinUSD",
  },
  {
    label: "Gross Profit",
    placeholder: "Enter Gross Profit",
    name: "grossProfiteinUSD",
  },
  {
    label: "Operating Expense",
    placeholder: "Enter Operating Expense",
    name: "operatingExpense",
  },
  {
    label: "Depreciation Expense",
    placeholder: "Enter Depreciation Expense",
    name: "depExpense",
  },
  {
    label: "Amortization Expense",
    placeholder: "Enter Amortization Expense",
    name: "amortExpense",
  },
  {
    label: "Interest Expense",
    placeholder: "Enter Interest Expense",
    name: "interestExpense",
  },
  {
    label: "Taxes Paid",
    placeholder: "Enter Taxes Paid",
    name: "taxesPaid",
  },
  {
    label: "Net Profit Before Tax",
    placeholder: "Enter Net Profit Before Tax",
    name: "netProfiteBeforeTaxinUSD",
  },
];
const HOME_RUN_BALANCE_SHEET = [
  {
    label: "Gross Fixed Assets",
    placeholder: "Enter Gross Fixed Assets",
    name: "grossFixedAssets",
  },
  {
    label: "Long Term Debt",
    placeholder: "Enter Long Term Debt",
    name: "longTermDebt",
  },
  {
    label: "Total Liabilities",
    placeholder: "Enter Total Liabilities",
    name: "totalLiabilities",
  },
  {
    label: "Retained Earnings",
    placeholder: "Enter Retained Earnings",
    name: "retainedEarnings",
  },
  {
    label: "Total Equity",
    placeholder: "Enter Total Equity",
    name: "totalEquity",
  },
];
const HOME_RUN_CASH_FLOW_STATEMENT = [...CASH_FLOW_STATEMENT];

const FINANCIAL_DATA_OWNER_GOAL = [...OWNER_GOAL];

const FINANCIAL_DATA_INCOME_STATEMENT = [
  {
    label: "Sales",
    placeholder: "Enter Sales",
    name: "salesinUSD",
  },
  {
    label: "Cost of Goods Sold",
    placeholder: "Enter Cost of Goods Sold",
    name: "costOfGoodsSoldinUSD",
  },
  {
    label: "Gross Profit",
    placeholder: "Enter Gross Profit",
    name: "grossProfiteinUSD",
  },
  {
    label: "Operating Expense",
    placeholder: "Enter Operating Expense",
    name: "operatingExpense",
  },
  {
    label: "Depreciation Expense",
    placeholder: "Enter Depreciation Expense",
    name: "depExpense",
  },
  {
    label: "Amortization Expense",
    placeholder: "Enter Amortization Expense",
    name: "amortExpense",
  },
  {
    label: "Interest Expense",
    placeholder: "Enter Interest Expense",
    name: "interestExpense",
  },
  {
    label: "Taxes Paid",
    placeholder: "Enter Taxes Paid",
    name: "taxesPaid",
  },

  {
    label: "Average Fully Loaded Payroll Expense",
    placeholder: "Enter Average Fully Loaded Payroll Expense",
    name: "avgPayrollExpense",
  },
  {
    label: "Number of Days In Payroll Period",
    placeholder: "Enter Number of Days In Payroll Period",
    name: "daysInPayroll",
  },

  {
    label: "Net Profit Before Tax",
    placeholder: "Enter Net Profit Before Tax",
    name: "netProfiteBeforeTaxinUSD",
  },
];
const FINANCIAL_DATA_BALANCE_SHEET = [
  {
    label: "Current Assets",
    placeholder: "Enter Current Assets",
    industryAvg: true,
    name: "currenAssets",
  },
  {
    label: "Cash",
    placeholder: "Enter Cash",
    name: "cash",
  },
  {
    label: "Accounts Receivable",
    placeholder: "Enter Accounts Receivable",
    name: "accountReceivableinUSD",
  },
  {
    label: "Inventory",
    placeholder: "Enter Inventory",
    name: "inventoryinUSD",
  },
  {
    label: "Gross Fixed Assets",
    placeholder: "Enter Gross Fixed Assets",
    name: "grossFixedAssets",
  },
  {
    label: "Total Assets",
    placeholder: "Enter Total Assets",
    name: "totalAssetsinUSD",
  },
  {
    label: "Current Liabilities",
    placeholder: "Enter Current Liabilities",
    name: "currenLiabilities",
  },
  {
    label: "Accounts Payable",
    placeholder: "Enter Accounts Payable",
    name: "accountPayableinUSD",
  },
  {
    label: "Long Term Debt",
    placeholder: "Enter Long Term Debt",
    name: "longTermDebt",
  },
  {
    label: "Debt Payments",
    placeholder: "Enter Debt Payments",
    name: "debtPayments",
  },
  {
    label: "Total Liabilities",
    placeholder: "Enter Total Liabilities",
    name: "totalLiabilities",
  },
  {
    label: "Retained Earnings",
    placeholder: "Enter Retained Earnings",
    name: "retainedEarnings",
  },
  {
    label: "Total Equity",
    placeholder: "Enter Total Equity",
    name: "totalEquity",
  },
];
const FINANCIAL_DATA_CASH_FLOW_STATEMENT = [...CASH_FLOW_STATEMENT];

const SVS_INCOME_STATEMENT = [
  {
    label: "Depreciation Expense",
    placeholder: "Enter Depreciation Expense",
    name: "depExpense",
  },
  {
    label: "Amortization Expense",
    placeholder: "Enter Amortization Expense",
    name: "amortExpense",
  },
  {
    label: "Interest Expense",
    placeholder: "Enter Interest Expense",
    name: "interestExpense",
  },
  {
    label: "Taxes Paid",
    placeholder: "Enter Taxes Paid",
    name: "taxesPaid",
  },
  {
    label: "Net Profit Before Tax",
    placeholder: "Enter Net Profit Before Tax",
    name: "netProfiteBeforeTaxinUSD",
  },

  {
    label: "Family Excess / Seller’s Discretionary Earnings",
    placeholder: "Enter Family Excess",
    name: "familyExcess",
  },
];

const SVS_BALANCE_SHEET = [
  {
    label: "Long Term Debt",
    placeholder: "Enter Long Term Debt",
    name: "longTermDebt",
  },
  {
    label: "Total Equity",
    placeholder: "Enter Total Equity",
    name: "totalEquity",
  },
  {
    label: "Capital Expenditures this period​",
    placeholder: "Enter Capital Expenditures",
    name: "capx",
  },
];

const HIDDEN_CASH_ANALYSIS = {
  salesToAssetsNeg:
    "It looks like there’s room for improvement here. Focus on selling more with your current assets. The good news? You likely don’t need to invest in additional equipment right now. Instead, work on refining your sales process to maximize what you already have.",
  salesToAssetsPoss:
    "Great work! You’re making excellent use of your equipment to generate sales. This is a good time to evaluate if additional equipment might be needed to sustain growth as you scale. Just keep an eye on capacity—if you’re nearing the limit, you could face a bottleneck down the line.",

  grossProfitPoss:
    "Fantastic job! Your gross profit is above the goal, which means your business is bringing in more cash. As long as you’ve accurately classified items in your cost of goods sold, you’re in great shape. Keep it up!",
  grossProfitNeg: `This is a warning sign that your business isn’t generating as much cash as it could. Start by checking three things:\n
  1- Are costs of goods sold classified correctly?\n
  2- Are your prices competitive, or are you leaving money on the table?\n
  3- Can you negotiate better prices for materials or labor?\n
  Addressing these areas can significantly improve your cash flow.`,

  OperatingExpensePoss:
    "You’re doing an excellent job managing expenses, and it’s paying off for your bottom line. Don’t hesitate to invest in the essentials that drive revenue, but be mindful not to overspend. If you’re planning for growth, there’s room to allocate resources strategically without sacrificing profitability.",
  OperatingExpenseNeg:
    "Spending more than planned can eat into your profitability. This is a great opportunity to review your costs and identify areas to trim. Focus on cutting out expenses that don’t directly support sales. Small changes here can make a big impact on your bottom line.",

  InventoryPoss:
    "Nicely done! Your inventory levels are low, which means less cash is tied up on the shelf. Just make sure you’re not running too lean—stockouts could impact sales. Use the PAR calculation to ensure you’re keeping just the right amount of inventory.",
  InventoryNeg:
    "Having too much inventory ties up cash that could be used elsewhere. Use the PAR calculation to determine the right amount of stock for each item, and work on selling down excess inventory. Consider running promotions or markdowns to move items more quickly and avoid storage costs.",

  apNeg:
    "Paying vendors too early might be limiting your cash flow. While it’s great to build strong vendor relationships, consider aligning your payments closer to industry norms. This will free up cash for your business while still maintaining good relationships and taking advantage of discounts when available.",
  apPoss:
    "Awesome work! You’re keeping cash in your business longer by delaying payments, which supports strong cash flow. Just make sure you’re not straining vendor relationships or missing out on discounts. Aim to align your payments with industry norms to keep things balanced.",

  arNeg:
    "If you’re making sales but struggling with cash flow, your customers might be taking too long to pay. Review your collection process and look for ways to encourage faster payments—offering early payment discounts could help. Collecting more upfront is another great strategy to improve your cash position.",
  arPoss:
    "You’re in a great position! Customers are paying on time, or you’re collecting cash upfront, which is excellent for cash flow. Just be careful not to be too aggressive with collections if your sales involve invoices—flexibility matters. Staying close to the industry average will help you stay adaptable.",
};

const CURRENT_RATIO = "currentRatio";
const QUICK_RATIO = "quickRatio";
const DEDT_EQUITY_RATIO = "debtEquityRatio";
const INVENTORY_TURNOVER = "inventoryTurnover";
const AR_TURNOVER = "arTurnover";
const GROSS_MARGIN = "grossMargin";
const LOW = "low";
const AVERAGE = "average";
const HIGH = "high";
const NET_MARGIN = "netMargin";
const SALES_TO_ASSETS = "salesToAssets";
const EBITDA = "ebidta";
const DCOH = "dcoh";
const DSCR = "dscr";
const ROA = "ROA";
const ROI = "ROI";
const AP_TURNOVER = "apTurnover";

const FDCalList = [
  {
    label: "Current Ratio",
    value: CURRENT_RATIO,
  },
  {
    label: "Quick Ratio",
    value: QUICK_RATIO,
  },
  {
    label: "Debt Equity Ratio",
    value: DEDT_EQUITY_RATIO,
  },
  {
    label: "Inventory Days",
    value: INVENTORY_TURNOVER,
  },
  {
    label: "AR Days",
    value: AR_TURNOVER,
  },
  {
    label: "Gross Profit Margin",
    value: GROSS_MARGIN,
  },
  {
    label: "Net Profit Margin",
    value: NET_MARGIN,
  },
  {
    label: "Sales To Assets",
    value: SALES_TO_ASSETS,
  },
  {
    label: "EBIDTA",
    value: EBITDA,
  },
  {
    label: "Days Cash On Hand",
    value: DCOH,
  },
  {
    label: "DSCR",
    value: DSCR,
  },
  {
    label: "Return On Assets",
    value: ROA,
  },
  {
    label: "Return On Investment",
    value: ROI,
  },
  {
    label: "AP Days",
    value: AP_TURNOVER,
  },
];

const GRAPH_TOOLTIP_SETTINGS = {
  bodyFont: 25,
  titleFont: 20,
  padding: 15,
};

module.exports = {
  GRAPH_TOOLTIP_SETTINGS,
  FDCalList,
  CURRENT_RATIO,
  QUICK_RATIO,
  SALES_TO_ASSETS,
  EBITDA,
  DCOH,
  DSCR,
  INVENTORY_TURNOVER,
  AR_TURNOVER,
  AP_TURNOVER,
  ROA,
  ROI,
  HIDDEN_CASH_ANALYSIS,
  NET_MARGIN,
  DEDT_EQUITY_RATIO,
  GROSS_MARGIN,
  LOW,
  AVERAGE,
  HIGH,
  PERIOD_DURATION,
  OWNER_GOAL,
  INCOME_STATEMENT,
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  HIDDEN_CASH_OWNER_GOAL,
  HIDDEN_CASH_INCOME_STATEMENT,
  HIDDEN_CASH_BALANCE_SHEET,
  FAST_MONEY_INCOME_STATEMENT,
  FAST_MONEY_BALANCE_SHEET,
  HOME_RUN_INCOME_STATEMENT,
  HOME_RUN_BALANCE_SHEET,
  HOME_RUN_CASH_FLOW_STATEMENT,
  FINANCIAL_DATA_INCOME_STATEMENT,
  FINANCIAL_DATA_OWNER_GOAL,
  FINANCIAL_DATA_BALANCE_SHEET,
  FINANCIAL_DATA_CASH_FLOW_STATEMENT,
  SVS_INCOME_STATEMENT,
  SVS_BALANCE_SHEET,
};
